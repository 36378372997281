import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default function useTenderReportsList() {
  const toast = useToast()
  const { t } = useI18nUtils()

  const refTendersListTable = ref(null)
  const profileFilter = ref(null)

  // Table Handlers
  const tableColumns = [
    'show_details',

    {
      key: 'tender_profile_name',
      label: t('Tender Profile'),
      sortable: true,
      thStyle: { width: '90% !important' },
    },
  ]

  const tableDetailColumns = [
    {
      key: 'tender_title',
      label: t('title'),
      sortable: true,
    },
    {
      key: 'tender_document_type',
      label: t('document type'),
      sortable: true,
    },
    {
      key: 'contract_authority_name',
      label: t('contract authority name'),
      sortable: true,
    },
    {
      key: 'publication_date',
      label: t('publication date'),
      sortable: true,
    },
    {
      key: 'submission_deadline',
      label: t('submission deadline'),
      sortable: true,
    },
    {
      key: 'url',
      sortable: true,
    },

  ]

  const perPage = ref(10)
  const totalTenders = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refTendersListTable.value ? refTendersListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTenders.value,
    }
  })

  const refetchData = () => {
    refTendersListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, profileFilter], () => {
    refetchData()
  })

  const fetchTenders = (ctx, callback) => {
    store
      .dispatch('tenders/fetchTenderReports', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        profile: profileFilter.value,
      })
      .then(response => {
        const {
          data,
          total,
        } = response

        console.log(data)
        callback(data)

        totalTenders.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: t('Error fetching tenders list'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchTenders,
    tableColumns,
    tableDetailColumns,
    perPage,
    currentPage,
    totalTenders,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTendersListTable,

    refetchData,

    profileFilter,
  }
}
