<template>
  <div>
    <!-- Filters -->
    <tender-reports-list-filters
      :profile-filter.sync="profileFilter"
      :profile-options="profileOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refTendersListTable"
        class="position-relative"
        :items="fetchTenders"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            class="vs-checkbox-con"
            @change="row.toggleDetails"
          >

            <span class="vs-label">{{ row.detailsShowing ? $t('Hide' ): $t('Show') }}</span>
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card>
            <b-table
              class="position-relative"
              :items="row.item.tenders"
              responsive
              :fields="tableDetailColumns"
              primary-key="id"
              show-empty
              :empty-text="$t('No matching records found')"
            >

              <!-- Column: Publication date -->
              <template #cell(publication_date)="data">
                <span class="text-nowrap ">
                  {{
                    new Date(data.value).toLocaleDateString('sl-si', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric'
                    })
                  }}
                </span>
              </template>

              <!-- Column: Deadline date -->
              <template #cell(submission_deadline)="data">
                <span class="text-nowrap">
                  {{
                    data.value ?
                      new Date(data.value).toLocaleDateString('sl-si', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric'
                      })

                      : ''
                  }}
                </span>
              </template>

              <!-- Column: url -->
              <template #cell(url)="data">
                <b-link
                  :href="data.value"
                  class="font-weight-bold d-block text-nowrap"
                  target="_blank"
                >
                  <feather-icon
                    :id="`invoice-row-${data.item.id}-preview-icon`"
                    icon="ExternalLinkIcon"
                    size="16"
                    class="mx-1"
                  />
                </b-link>
              </template>

              <b-button
                size="sm"
                variant="outline-secondary"
                @click="row.toggleDetails"
              >
                {{ $t('Hide Details') }}
              </b-button>
            </b-table>
          </b-card>
        </template>

        <!-- Column: Title -->
        <template #cell(tender_profile_name)="data">
          <div
            class="mb-1 mt-1 font-weight-bold"
          >
            {{ data.item.tender_profile_name }}
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t('Showing {from} to {to} of {of} entries',{from: dataMeta.from, to: dataMeta.to , of:dataMeta.of }) }}</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalTenders"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BLink,
  BPagination, BFormCheckbox, BButton, BTableLite,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useTendersList from '@/views/pages/tenders/tenders-list/useTendersList'
import useTenderReportsList from '@/views/pages/tenders/tender-reports-list/useTenderReportsList'
import TenderReportsListFilters from '@/views/pages/tenders/tender-reports-list/TenderReportsListFilters'
import store from '@/store'

export default {
  components: {
    TenderReportsListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BFormCheckbox,
    BButton,
    vSelect,
  },
  data() {
    return {
      profileOptions: [],
    }
  },
  created() {
    store.dispatch('tenders/fetchAllProfiles')
      .then(response => {
        this.profileOptions = response.data.map(client => ({
          label: client.name,
          value: client.id,
        }))
      })
      .catch(error => {
        console.log(error)
      })
  },
  setup() {
    const isAddNewClientSidebarActive = ref(false)

    const selectedTenderData = ref(false)

    const {
      fetchTenders,
      tableColumns,
      tableDetailColumns,
      perPage,
      currentPage,
      totalTenders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      refTendersListTable,
      profileFilter,
    } = useTenderReportsList()

    return {

      // Sidebar
      isAddNewClientSidebarActive,

      fetchTenders,
      tableColumns,
      tableDetailColumns,
      perPage,
      currentPage,
      totalTenders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      selectedTenderData,

      refTendersListTable,
      profileFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
